.layout {
  min-height: 100vh;
}

.layout-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  color: white;
  background: #1890ff;
  /* background: linear-gradient(135deg, #00c6ff 0%, #a139ff 100%); */
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.layout-content {
  margin: 20px;
}

.layout-footer {
  text-align: center;
  padding: 10px 50px;
  color: black;
}
