.login-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #00c6ff 0%, #a139ff 100%);
}

.login-card {
  background: white;
  padding: 40px;
  border-radius: 16px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  backdrop-filter: blur(10px);
}

.login-header {
  text-align: center;
  margin-bottom: 40px;
}

.login-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 8px;
  background: linear-gradient(90deg, #00c6ff, #a139ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login-subtitle {
  color: #666;
  font-size: 14px;
  margin-bottom: 0;
}

.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-weight: 500;
  font-size: 14px;
}

.login-form .ant-input-affix-wrapper {
  border-radius: 8px;
  padding: 12px 16px;
  border: 2px solid #eef2f7;
  transition: all 0.3s ease;
}

.login-form .ant-input-affix-wrapper:hover,
.login-form .ant-input-affix-wrapper:focus {
  border-color: #00c6ff;
  box-shadow: 0 0 0 2px rgba(0, 198, 255, 0.1);
}

.login-form .ant-input {
  padding: 8px 11px;
  font-size: 14px;
}

.login-form .ant-input::placeholder {
  color: #aab0b7;
}

.input-icon {
  color: #aab0b7;
  font-size: 16px;
}

.remember-me-container {
  margin-bottom: 24px;
}

.ant-checkbox-wrapper {
  color: #666;
  font-size: 14px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00c6ff;
  border-color: #00c6ff;
}

.login-button {
  width: 100%;
  height: 48px;
  border: none;
  border-radius: 24px;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}

.login-button:hover {
  opacity: 0.9;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 198, 255, 0.3);
  background: linear-gradient(90deg, #00c6ff 0%, #a139ff 100%);
}

.login-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 198, 255, 0.3);
}

/* Responsive adjustments */
@media (max-width: 480px) {
  .login-card {
    padding: 30px 20px;
  }
  
  .login-title {
    font-size: 28px;
  }
  
  .login-button {
    height: 44px;
    font-size: 14px;
  }
}
