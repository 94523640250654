body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.policy .ant-input {
  border: none;
  border-bottom: 1px dashed gray;
}

.policy .ant-input-group-addon {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.ant-layout {
  background: white;
}

.ant-input::placeholder, 
.ant-select-selection-placeholder {
  color: rgb(60, 60, 60) !important; /* Change to your desired color */
  opacity: 1;
}

.mr-icon {
  margin-right: 4px;
}
.highlight {
  background: #ffffc8 !important;
}

.highlight-modified {
  background-color: #f4f6f9 !important;  
  color: #ff6b6b;  
}

.border-bottom {
  text-decoration: underline;
  text-underline-position: under;
  
}

.bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.container {
  width: 100%;
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
