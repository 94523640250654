
.register-title {
  margin-bottom: 16px;
  text-align: center;
}

.register-form {
  max-width: 300px;
  margin: auto;
}

.register-form-button {
  width: 100%;
}
