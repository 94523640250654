.edit-profile-title {
  text-align: center;
  margin-bottom: 24px;
}

.edit-profile-button {
  width: 100%;
}

.container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.profile-card .ant-card-head-title {
  font-size: 20px;
  font-weight: 600;
}

.avatar-wrapper {
  position: relative;
  display: inline-block;
}

.profile-avatar {
  border: 4px solid #f0f0f0;
  cursor: pointer;
}

.upload-overlay {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #1890ff;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s;
}

.upload-overlay:hover {
  background: #40a9ff;
}

.ant-upload-button {
  width: 100%;
}

.ant-form-item-label {
  font-weight: 500;
}

.profile-section {
  transition: all 0.3s ease;
}

.profile-section:hover {
  transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container {
    padding: 16px;
  }
}

/* Animation for success messages */
@keyframes slideIn {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.ant-message-notice-content {
  animation: slideIn 0.3s ease;
}

/* Additional styling for form elements */
.ant-form-item {
  margin-bottom: 24px;
}

.ant-input,
.ant-input-password {
  border-radius: 6px;
}

.ant-btn {
  border-radius: 6px;
}

.ant-card-body {
  padding: 24px;
}
