.page-break {
  break-before: always; 
}
.page-break-after {
  break-after: always; 
}
.cover-page, .page-2 {
  display: none;
}
.page-1 {
  display: block;
}

.bottom-absolute-page-1, 
.bottom-absolute-page-2 {
  display: none;
}

/* Add these new styles for screen/print views */
.print-only {
  display: none;
}

.screen-view {
  display: block;
}

.print-page {
  display: none;
}

@media print {
  .cover-page, 
  .page-1, 
  .page-2,
  .bottom-absolute-page-1, 
  .bottom-absolute-page-2 {
    display: block;
  }

  .cover-page-setup, .page-1-setup, .page-2-setup {
    position: relative; 
    width: 100%;
    height: auto;
    margin: 0;
    /* padding: 20px; */
    page-break-after: always;
  }
  
  .page-1-setup .bottom-absolute-page-1, 
  .page-2-setup .bottom-absolute-page-2 {
    position: relative;
    bottom: 0; 
    width: 92%; 
    padding: 6px 0; 
    text-align: center; 
    color: #333; 
    border-top: 1px solid #ddd;
    font-size: 9px;
  }

  /* Remove the flex display since we don't need to space between date and disclosure anymore */
  .bottom-absolute-page-1 div,
  .bottom-absolute-page-2 div {
    display: block;
    text-align: center;
  }

  /* Print-specific styles */
  .screen-view {
    display: none;
  }

  .print-only {
    display: block;
  }

}

@page {
  size: letter;
  margin: 0.3in;
  margin-top: 20px;
  margin-bottom: 10px;

  @top-left {
    content: '';
  }

  @top-right {
    content: '';
  }

  @bottom-left {
    content: '';
  }

  @bottom-right {
    content: '';
  }
 
}



.bottom-absolute-page-1,
.bottom-absolute-page-2 {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  width: calc(100% - 40px);
}

h3 {
  margin-bottom: 10px;
}