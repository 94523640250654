
.forgot-password-title {
  margin-bottom: 16px;
}

.forgot-password-instructions {
  margin-bottom: 24px;
  color: #555;
  font-size: 14px;
}

.forgot-password-form {
  max-width: 300px;
  margin: auto;
}

.forgot-password-button {
  width: 100%;
}
